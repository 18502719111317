<template>
  <div>
    <fixed-header v-if="!$isMobile()" :threshold="50">
      <div class="fixed-header">
        <b-button type="is-text" size="is-small" icon-left="angle-left" @click="onClickBack">
          목록
        </b-button>
        <p style="margin: 0">{{ title }}</p>
        <div>
          <b-button
            v-if="isLogin"
            type="is-text"
            size="is-small"
            icon-left="edit"
            @click="onClickEdit"
          >
            수정
          </b-button>
          <b-button
            v-if="isLogin"
            type="is-text"
            size="is-small"
            icon-left="trash"
            @click="onClickTrash"
          >
            삭제
          </b-button>
        </div>
      </div>
    </fixed-header>
    <froala-view class="pl-2 pr-2 mb-4" v-model="content"></froala-view>
  </div>
</template>

<script>
import { computed, defineComponent, onMounted, ref } from '@vue/composition-api';
import FixedHeader from 'vue-fixed-header';
import client from '../api/client';
import { useRouter } from '@/use-router';
import { DialogProgrammatic as Dialog } from 'buefy';
import store from '../store/index';

export default defineComponent({
  name: 'Detail',
  components: {
    FixedHeader,
  },
  setup() {
    const router = useRouter();

    const category = ref('');
    const title = ref('');
    const content = ref('');
    const isLogin = computed(() => store.getters.isLogin);

    function onClickBack() {
      router.go(-1);
    }

    function onClickEdit() {
      const params = { category: category.value, title: title.value };
      if (category.value === 'projects') {
        params.projectCategory = router.currentRoute.params.projectCategory;
      }

      router.push({ name: 'Edit', params });
    }

    function onClickTrash() {
      Dialog.confirm({
        message: `${title.value}를 삭제하시겠습니까?`,
        onConfirm: async () => {
          await client.delete(category.value, title.value);

          router.go(-1);
        },
      });
    }

    onMounted(async () => {
      category.value = router.currentRoute.params.category;
      title.value = router.currentRoute.params.title;

      if (category.value === 'projects') {
        category.value = `projects/${router.currentRoute.params.projectCategory}`;
      }

      const result = await client.getDetail(category.value, title.value);

      content.value = result.data.html;
    });

    return {
      title,
      content,
      isLogin,

      onClickBack,
      onClickEdit,
      onClickTrash,
    };
  },
});
</script>

<style>
.fixed-header {
  display: flex;
  z-index: 999;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  padding: 8px 0px;
}

.fixed-header.vue-fixed-header--isFixed {
  position: fixed;
  left: 33.33333337%;
  top: 0;
  width: 66.66666663%;
}
</style>
